<template>
  <div>
    <h1>Account</h1>
    <v-form>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>About yourself</v-card-title>
            <v-card-text>
              <v-text-field data-cy="firstName" v-model="signupRequest.firstName" :rules="firstNameRequired"
                label="First name"></v-text-field>
              <v-text-field data-cy="lastName" v-model="signupRequest.lastName" :rules="lastNameRequired"
                label="Last name"></v-text-field>
              <v-text-field data-cy="email" v-model="signupRequest.email" :rules="emailRules" label="Email"
                type="email"></v-text-field>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>Your account</v-card-title>
            <v-card-text>
              <v-text-field data-cy="username" v-model="signupRequest.username" :rules="usernameRules" label="Username"
                required></v-text-field>
              <v-text-field data-cy="password" v-model="signupRequest.password" :rules="passwordRules" label="Password"
                type="password" required></v-text-field>
              <v-text-field data-cy="passwordAgain" v-model="passwordAgain" :rules="passwordRulesAgain"
                label="Password again" type="password" required></v-text-field>
              <v-alert type="error" v-if="error">{{ error }}</v-alert>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn data-cy="signupSubmit" color="primary" @click="signup">Create account</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'SignupPage',

  data: () => ({
    error: null,
    signupRequest: {},
    passwordAgain: '',
    usernameRules: [
      v => !!v || 'Username is required',
      v => (v && v.length >= 3) || 'Username must be greater than 3 characters'
    ],
    passwordRules: [
      v => !!v || 'Password is required',
      v => (v && v.length >= 8) || 'Password must be greater than 8 characters'
    ],
    emailRules: [
      v => !!v || 'Email is required',
      v => /.+@.+/.test(v) || 'Email must be valid'
    ],
    firstNameRequired: [
      v => !!v || 'First name is required'
    ],
    lastNameRequired: [
      v => !!v || 'Last name is required'
    ]
  }),

  computed: {
    passwordRulesAgain () {
      return [
        v => !!v || 'Password confirmation is required',
        v => this.checkPasswordConfirmation(v)
      ]
    }
  },

  methods: {
    checkPasswordConfirmation (value) {
      return value === this.signupRequest.password || 'Passwords do not match'
    },

    signup () {
      fetch('/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.signupRequest)
      })
        .then(response => {
          if (response.status === 200) {
            this.$router.push('/')
          } else throw new Error('Signup failed')
        })
        .catch(error => {
          this.error = error.message
        })
    }
  }
}
</script>
